





























































import './TeamMember.scss';
import './TeamMemberAdaptive.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IProjectTeamState, IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { IProjectTasksState, ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IProject } from '@store/modules/project/Interfaces';
import { ProjectActions } from '@store/modules/project/Types';
import { IAddTaskPostData } from '@store/modules/project-tasks/interfaces/ApiRequests';
import { ProjectTasksActions } from '@store/modules/project-tasks/Types';
import { UserActionTypes } from '@store/modules/user/Types';
import { IUserState } from '@store/modules/user/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { AboutProjectActions } from "@store/modules/about-project/Types";
import { IJuristicEntity } from "@store/modules/about-project/Interfaces";

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');
const NSProjectTasks = namespace('storeProjectTasks');
const NSDocuments = namespace('storeProjectDocuments');
const NSAboutProject = namespace('storeAboutProject');

@Component({
    name: 'TeamMember',

    components: {
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        MemberHeader: () => import('./components/MemberHeader.vue'),
        MemberMainInfo: () => import('./components/MemberMainInfo.vue'),
        MemberStatuses: () => import('./components/MemberStatuses.vue'),
        MemberTasks: () => import('./components/MemberTasks.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
        MemberPopups: () => import('./components/MemberPopups.vue'),
        PopupTaskDetails: () => import('@components/popups/PopupTaskDetails.vue'),
        PopupAddTask: () => import('@components/popups/PopupAddTask.vue'),
    },
})
export default class TeamMember extends Vue {
    $refs!: {
        memberPopups: HTMLFormElement,
        popupAddTask: HTMLFormElement,
        popupTaskDetails: HTMLFormElement,
    }

    @NSProjectTeam.State((state: IProjectTeamState) => state.viewedMember) viewedMember!: IMemberInfo;
    @NSProjectTeam.Getter('projectTeam') projectTeam!: IMemberInfo[];
    @NSProjectTeam.Getter('projectTeamLoaded') projectTeamLoaded!: any;
    @NSProjectTeam.Action(ProjectTeamActions.A_SET_VIEWED_MEMBER) setViewedMember!: (member: IMemberInfo) => void;
    @NSProjectTeam.Action(ProjectTeamActions.A_DEACTIVATE_MEMBER) deactivateMember!: (payload: { appId: number, userId: string }) => Promise<void>;
    @NSProjectTeam.Action(ProjectTeamActions.A_ENRICH_MEMBER_INFO) getProjectTeamInfo!: (payload: { projectId: number, userId: string }) => Promise<void>;
    @NSProjectTeam.Action(ProjectTeamActions.A_GET_MEMBER_RIGHTS) getMemberRights!: (projectId: string | number) => Promise<void>;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProject.Action(ProjectActions.A_GET_PROJECT) reloadDocuments!: (any) => Promise<void>;
    @NSProject.Action(ProjectActions.A_GET_MEMBER_ROLES) getMemberRoles!: (appId: number) => Promise<void>;
    @NSProjectTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS) getProjectTasks!: (projectId: number) => Promise<void>;
    @NSProjectTasks.State((state: IProjectTasksState) => state.tasks) allTasks!: ITask[];
    @NSProjectTasks.Action(ProjectTasksActions.A_CREATE_NEW_TASK) sendAddTaskFormAction!: ({ postData: IAddTaskPostData, type: string }) => Promise<any>;
    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Action(UserActionTypes.A_GET_USER) updateUser!: (payload) => Promise<void>;
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];
    @NSAboutProject.Action(AboutProjectActions.A_GET_JURISTIC_ENTITIES) getJuristicEntities!: (projectId: string | number) => Promise<any>;
    @NSAboutProject.Action(AboutProjectActions.A_SET_JURISTIC_ENTITIES) setJuristicEntities!: (entity: IJuristicEntity) => void;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOCUMENT_STATUSES) getDocsStatuses!: (id: number) => void;
    @NSDocuments.Getter('statuses') documentStatuses!: any;

    selectedEditItem: any = null;
    updatedData: any = null;
    showPreloaderOnDeactivateConfirmation: boolean = false;
    timeoutAfterDeactivateMember: number = 500;
    informationPopupShow: boolean = false;
    informationPopupText: string = '';
    showInformationPopupDuration: number = 2000;
    selectedTaskToView = {} as ITask;
    popupTaskDetailsToggler: boolean = false;
    popupAddTaskToggler: boolean = false;

    mounted() {
        if (!this.viewedMember.uuid) {
            const viewedMemberUuid = this.$route.params.memberUuid;
            const viewedProjectMember = this.projectTeam.find(member => member.uuid === viewedMemberUuid)
            if (viewedProjectMember) this.setViewedMember(viewedProjectMember);
        }
        this.getStatusesRightsRoles();
    }

    @Watch('projectTeamLoaded')
    watchProjectTeamLoaded() {
        if (this.projectTeamLoaded && !this.viewedMember.uuid) {
            const viewedMemberUuid = this.$route.params.memberUuid;
            const viewedProjectMember = this.projectTeam.find(member => member.uuid === viewedMemberUuid)
            if (viewedProjectMember) this.setViewedMember(viewedProjectMember);
        }
        if (!this.documentStatuses.length) {
            this.getStatusesRightsRoles();
        }
    }

    get memberMainInfo() {
        return {
            uuid: this.viewedMember.uuid,
            position: this.viewedMember.position,
            phone: this.viewedMember.phone,
            email: this.viewedMember.email,
            role: this.viewedMember.role,
            legalEntity: this.viewedMember.legalEntity,
        };
    }

    get memberStatuses() {
        return {
            active: this.viewedMember.active,
            legalStatus: 'physical',
            signingStatuses: this.viewedMember.docStatuses,
        };
    }

    get executors() {
        return [{ label: this.viewedMember.fullName, uuid: this.viewedMember.uuid }];
    }

    get memberTasks(): ITask[] {
        return this.allTasks.filter((task) => task.executor?.uuid === this.viewedMember.uuid);
    }

    get projectId() {
        return this.$route.params.projectId;
    }

    get popupTaskDetailsTogglerSate() {
        return this.popupTaskDetailsToggler;
    }

    get popupAddTaskTogglerState() {
        return this.popupAddTaskToggler;
    }

    get selectedTask() {
        return this.selectedTaskToView;
    }

    getStatusesRightsRoles() {
        this.getDocsStatuses(parseInt(this.projectId, 10));
        this.getMemberRights(parseInt(this.projectId, 10));
        this.getMemberRoles(parseInt(this.projectId, 10));
    }

    successEditProjectMember(selectedMemberId: string): void {
        this.showInformationPopup('Профиль изменен');
        this.getProjectTeamInfo({ projectId: Number(this.projectId), userId: selectedMemberId })
            .then(() => {
                if (selectedMemberId === this.userData.userInfo.id) {
                    this.updateUser({ userId: this.userData.userInfo.id, appId: this.projectId });
                }
            });
    }

    openEditMemberPopup() {
        if (!this.juristicEntities.length) {
            this.getJuristicEntities(this.projectId)
                .then((juristicEntities: IJuristicEntity) => this.setJuristicEntities(juristicEntities));
        }

        this.closeAllPopup();
        this.selectedEditItem = Object.assign(
            JSON.parse(JSON.stringify(this.memberMainInfo)),
            {name: this.viewedMember.fullName}
        );
        this.$refs.memberPopups.openMemberEditPopup();
    }

    disableUser() {
        this.deactivateMember({
            appId: parseInt(this.projectId, 10),
            userId: this.memberMainInfo.uuid,
        }).then(() => {
            this.showPreloaderOnDeactivateConfirmation = true;
            setTimeout(() => {
                this.reloadDocuments({ projectId: this.projectId, reloadProjectTeam: true }).then(() => {
                    this.$router.push({ path: `/project/${this.projectId}/project-team` });
                })
            }, this.timeoutAfterDeactivateMember);
            this.showInformationPopup('Пользователь отключен');
        });
    }

    openConfirmDisableUserPopup() {
        this.closeAllPopup();
        this.$refs.memberPopups.showConfirmPopup();
    }

    showInformationPopup(text) {
        this.informationPopupShow = true;
        this.informationPopupText = text;

        setTimeout(() => {
            this.informationPopupShow = false;
            this.informationPopupText = '';
        }, this.showInformationPopupDuration);
    }

    reloadStoreTasks() {
        this.getProjectTasks(Number(this.projectId));
    }

    showTaskDetail(task) {
        this.closeAllPopup();
        this.selectedTaskToView = task;
        this.popupTaskDetailsToggler = true;
    }

    switchPopupToggler(nameToggler, state) {
        this[nameToggler] = state;
    }

    closeAllPopup() {
        for (let ref in this.$refs) {
            this.$refs[ref]?.closePopup();
        }
    }

    addingButtonClick() {
        this.closeAllPopup();
        this.popupAddTaskToggler = true;
    }

    addTask(taskFormData: IAddTaskPostData, isSubtask: boolean = false) {
        this.sendAddTaskForm(taskFormData, isSubtask);
    }

    sendAddTaskForm(taskFormData: IAddTaskPostData, isSubtask: boolean = false) {
        this.sendAddTaskFormAction({ postData: taskFormData, type: 'task' })
            .then(() => {
                this.showInformationPopup('Задача успешно создана');
                this.popupAddTaskToggler = false;
                this.reloadStoreTasks();
        });
    }
}
